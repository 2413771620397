import { parseIntNanSafe } from '@digital-magic/ts-common-utils'
import { BuildingAddress } from '@api/endpoints/buildings/houses/types'

type BuildingItem = {
  address: BuildingAddress
}

const splitAddressByNumericPart = (address: BuildingAddress): [string, number | undefined] => {
  const match = address.match(/^(.*?)(\d+)$/)
  return match ? [match[1], parseIntNanSafe(match[2])] : [address, undefined]
}

export const addressSortComparator = (a: BuildingItem, b: BuildingItem): number => {
  const [streetA, houseA] = splitAddressByNumericPart(a.address)
  const [streetB, houseB] = splitAddressByNumericPart(b.address)
  if (streetA !== streetB || !houseA || !houseB) {
    return streetA.localeCompare(streetB)
  } else {
    return houseA - houseB
  }
}
