import styled, { css } from 'styled-components'

export const HousesStyled = styled('div')(
  ({ theme }) => css`
    .house-section {
      padding: ${theme.spacing(4)};
      background: ${theme.colors.grey200};
    }
    .no-data {
      padding: 16px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      background-color: #fafafa;
    }
  `
)
